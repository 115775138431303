import { useState, useEffect } from "react"
import {useGetUserPartnerTilesQuery, useGetUserPartnersQuery, useRemoveTileFromHomeMutation} from '../features/api'
import {ShortPartnerTileI, MenuItemI} from '@homefile/components/dist/interfaces'
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import {ResponseType} from '../interfaces/DataInterfaces';
import {useCustomToast} from '@homefile/components'
import { openDialog } from "../features/dialog/dialogSlice";

export const usePartnerInfo = () => {
    const {data} = useGetUserPartnerTilesQuery(null)
    const user = useAppSelector((state: RootState) => state.auth.user)
    const [tiles, updateTiles] = useState<ShortPartnerTileI[]>([])
    const [mainHomeTile, updateMainHomeTile] = useState<ShortPartnerTileI>({} as ShortPartnerTileI)
    const [homeTiles, updateHomeTiles] = useState<ShortPartnerTileI[]>([])
    const [mainTile, updateMainTile] = useState<ShortPartnerTileI>({} as ShortPartnerTileI)
    const [showMainPartnerTile, updateShowMainPartnerTile] = useState(false)
    const [showPartnerTiles, updateShowPartnerTiles] = useState(false)
    const {data: partnerData} = useGetUserPartnersQuery(null)
    const [transformHomeTiles, updateTransformHomeTiles] = useState(false)
    const [homeTileData, updateHomeTileData] = useState([] as any)
    const [removeTile] = useRemoveTileFromHomeMutation()
    const toast = useCustomToast()
    const [mainPartner, updateMainPartner] = useState({} as any)
    const dispatch = useAppDispatch()

    const handleRemoveTileFromHome = async (id: string) => {
        const removedTile: ResponseType = await removeTile(id)

        if (removedTile.data) {
            toast({
                title: 'Success',
                description: `Tile Removed`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
                })
        } else if (removedTile.error) {
            if ('status' in removedTile.error) {
                if (removedTile.error.status === 403) {
                    dispatch(openDialog({message: "Error: access denied, no permissions to remove partner tile."}))
                } else {
                    toast({
                        title: 'Error',
                        description: `Something went wrong, please try again.`,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        position: "top-right"
                    })
                }
            } else {
                toast({
                    title: 'Error',
                    description: `Something went wrong, please try again.`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                })
            }
        }
    }

    // GET partner tiles
    useEffect(() => {
        if (data) {
            const mainPartner = data.find((pData: any) => pData.partner.code === user.partnerCode)
            if (mainPartner) {
                if (mainPartner.partner.code !== "homefile") {
                    updateMainPartner(mainPartner.partner)
                    let tempMainTile: ShortPartnerTileI = {} as ShortPartnerTileI

                    // here need to target only the main 'sponsored' partner tile to pass to MainPartnerTileModule view
                    if (mainPartner.tiles && mainPartner.tiles.length > 0) {
                        const handleClick = () => {
                            window.open(data[0].tiles[0].buttonLink,"_blank")
                        }
                        const returnTile: ShortPartnerTileI = {
                            buttonText: mainPartner.tiles[0].buttonLabel,
                            onClick: handleClick,
                            description: mainPartner.tiles[0].description,
                            logo: mainPartner.tiles[0].logo,
                            partnerName: mainPartner.partner.name,
                            websiteUrl: mainPartner.tiles[0].url,
                            imageUrl: mainPartner.tiles[0].primaryImage,
                            socialLinks: mainPartner.partner.socialMediaLinks,
                            _id: mainPartner.tiles[0]._id
                        }
                        tempMainTile = {...returnTile}
                    }

                    updateMainTile(tempMainTile)
                }

                if (data.length > 1) {
                    let tempTiles: ShortPartnerTileI[] = [] 
    
                    // here need to target all the other partner tiles besides the first or main 'sponsored' tile for the PartnerTilesModule view
                    data.filter((pData: any) => pData.partner.code !== user.partnerCode).forEach((partnerData: any) => {
                        if (partnerData.tiles && partnerData.tiles.length > 0) {
                            partnerData.tiles.forEach((tile: any) => {
                                const handleClick = () => {
                                    window.open(tile.buttonLink,"_blank")
                                }
    
                                const returnTile: ShortPartnerTileI = {
                                    buttonText: tile.buttonLabel,
                                    onClick: handleClick,
                                    description: tile.description,
                                    logo: tile.logo,
                                    partnerName: partnerData.partner.name,
                                    websiteUrl: tile.url,
                                    imageUrl: tile.primaryImage,
                                    socialLinks: partnerData.partner.socialMediaLinks,
                                    _id: tile._id,
                                }
                                tempTiles = [...tempTiles, returnTile]
                            })
                        }
                    })
    
                    updateTiles(tempTiles)
                } else {
                    updateTiles([])
                }

            } else if (data.length > 0) {
                let tempTiles: ShortPartnerTileI[] = [] 
                // no main partner user signed up with or is sponsored by
                // here need to target all the other partner tiles besides the first or main 'sponsored' tile for the PartnerTilesModule view
                data.forEach((partnerData: any) => {
                    if (partnerData.tiles && partnerData.tiles.length > 0) {
                        partnerData.tiles.forEach((tile: any) => {
                            const handleClick = () => {
                                window.open(tile.buttonLink,"_blank")
                            }

                            const returnTile: ShortPartnerTileI = {
                                buttonText: tile.buttonLabel,
                                onClick: handleClick,
                                description: tile.description,
                                logo: tile.logo,
                                partnerName: partnerData.partner.name,
                                websiteUrl: tile.url,
                                imageUrl: tile.primaryImage,
                                socialLinks: partnerData.partner.socialMediaLinks,
                                _id: tile._id
                            }
                            tempTiles = [...tempTiles, returnTile]
                        })
                    }
                })
                updateMainTile({} as ShortPartnerTileI)
                updateTiles(tempTiles)
            } else {
                updateMainTile({} as ShortPartnerTileI)
                updateTiles([])
            }

        }
    }, [data, user])

    useEffect(() => {
        if (transformHomeTiles && homeTileData && partnerData && user) {
            handleHomeTileTransform(homeTileData)
        }
    }, [transformHomeTiles, homeTileData, partnerData, user])

    const checkHomeTiles = (data: any) => {
        if (data) {
            updateHomeTileData(data)
            updateTransformHomeTiles(true)
        }
    }

    // for GET home/homeId data.tileList
    const handleHomeTileTransform = (data: any) => {
        
        if (data) {
            const mainPartner = partnerData.find((pData: any) => pData.code === user.partnerCode)
            if (mainPartner) {
                if (mainPartner.code !== "homefile") {
                    updateShowMainPartnerTile(true)
                    let tempMainTile: ShortPartnerTileI = {} as ShortPartnerTileI
                    
                    // here need to target only the main 'sponsored' partner tile to pass to MainPartnerTileModule view
                    const mainTile = data.find((tileData: any) => tileData.partner === mainPartner._id)
                    if (mainTile) {
                        if (mainTile.active) {
                            const handleClick = () => {
                                window.open(mainTile.buttonLink,"_blank")
                            }
                            const returnTile: ShortPartnerTileI = {
                                buttonText: mainTile.buttonLabel,
                                onClick: handleClick,
                                description: mainTile.description,
                                logo: mainTile.logo,
                                partnerName: mainPartner.name,
                                websiteUrl: mainTile.url,
                                imageUrl: mainTile.primaryImage,
                                socialLinks: mainPartner.socialMediaLinks,
                                _id: mainTile._id
                            }
                            tempMainTile = {...returnTile}
                        }
                    }
                    updateMainHomeTile(tempMainTile)
                } else if (mainPartner.code === "homefile") {
                    updateShowMainPartnerTile(false)
                }

                if (data.length > 1) {
                    updateShowPartnerTiles(true)
                    let tempTiles: ShortPartnerTileI[] = [] 

                    // here need to target all the other partner tiles besides the first or main 'sponsored' tile for the PartnerTilesModule view
                    data.filter((tData: any) => tData.partner !== mainPartner._id).forEach((tile: any) => {
                        if (tile.active) {
                            const handleClick = () => {
                                window.open(tile.buttonLink,"_blank")
                            }

                            const associatedPartner = partnerData.find((pData: any) => pData._id === tile.partner)
                            if (associatedPartner) {
                                const returnTile: ShortPartnerTileI = {
                                    buttonText: tile.buttonLabel,
                                    onClick: handleClick,
                                    description: tile.description,
                                    logo: tile.logo,
                                    partnerName: associatedPartner.name,
                                    websiteUrl: tile.url,
                                    imageUrl: tile.primaryImage,
                                    socialLinks: associatedPartner.socialMediaLinks,
                                    _id: tile._id,
                                    menuItems: [{
                                        handleClick: () => {handleRemoveTileFromHome(tile._id)},
                                        label: "Remove Tile"
                                    }],
                                    urlText: tile.metadata ? tile.metadata.urlText : undefined
                                }
                                tempTiles = [...tempTiles, returnTile]
                            }
                        }
                    })
                    updateHomeTiles(tempTiles)
                } else {
                    updateShowPartnerTiles(false)
                    updateHomeTiles([])
                }
            } else if (data.length > 0) {
                updateShowMainPartnerTile(false)
                updateShowPartnerTiles(true)
                let tempTiles: ShortPartnerTileI[] = [] 
                // no main partner user signed up with or is sponsored by
                // here need to target all the other partner tiles besides the first or main 'sponsored' tile for the PartnerTilesModule view
                data.forEach((tile: any) => {
                    if (tile.active) {
                        const handleClick = () => {
                            window.open(tile.buttonLink,"_blank")
                        }

                        const associatedPartner = partnerData.find((pData: any) => pData._id === tile.partner)
                        if (associatedPartner) {
                            const returnTile: ShortPartnerTileI = {
                                buttonText: tile.buttonLabel,
                                onClick: handleClick,
                                description: tile.description,
                                logo: tile.logo,
                                partnerName: associatedPartner.name,
                                websiteUrl: tile.url,
                                imageUrl: tile.primaryImage,
                                socialLinks: associatedPartner.socialMediaLinks,
                                _id: tile._id,
                                menuItems: [{
                                    handleClick: () => {handleRemoveTileFromHome(tile._id)},
                                    label: "Remove Tile"
                                }],
                                urlText: tile.metadata ? tile.metadata.urlText : undefined
                            }
                            tempTiles = [...tempTiles, returnTile]
                        }
                    }
                })
                updateHomeTiles(tempTiles)
            } else {
                updateShowMainPartnerTile(false)
                updateShowPartnerTiles(false)
                updateHomeTiles([])
                updateMainHomeTile({} as ShortPartnerTileI)
            }
        }
    }

    return {
        tiles,
        mainTile,
        showMainPartnerTile,
        showPartnerTiles,
        partnerData,
        homeTiles,
        mainHomeTile,
        checkHomeTiles,
        mainPartner
    }
}
