import {useState, useEffect} from 'react';
import {HomeMonitor, HomeMonitorSteps, MonitorAlerts, Notifications} from '@homefile/components';
import {MonitorAlertsType} from '@homefile/components/dist/interfaces'
import {Flex, Stack} from '@chakra-ui/react'
import {fullStateObj} from '../../../util/stateArray'

const stepAlerts: Record<number, MonitorAlertsType> = {
    2: 'smoke-detector-expired',
    3: 'change-air-filter',
    5: 'smoke-detector-battery',
}

export const HomeMonitorModule = (address: any) => {
    const [date, updateDate] = useState("")
    const [location, updateLocation] = useState("")
    const [alertId, setAlertId] = useState<MonitorAlertsType>('smoke-detector-battery')

    useEffect(() => {
        const date = new Date()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const year = date.getFullYear().toString().substr(-2)
        const tDate = `${month}-${day}-${year}`
        updateDate(tDate)
    }, [])

    useEffect(() => {
        const city = `${address.address.city}`
        const state: string = `${address.address.state}`
        const lState = fullStateObj[state] || ""
        updateLocation(`${city}, ${lState}`)
    }, [address])
    
    const handleAlertClick = (step: number) => {
        setAlertId(stepAlerts[step])
    }

    return (
        <HomeMonitor>
          <Stack spacing="base">
            <Flex gap="base">
              <MonitorAlerts alertId={alertId} />
              <Notifications date={date} local={location} />
            </Flex>
            <HomeMonitorSteps
              stepsWithAlerts={[2, 3, 5]}
              onStepClick={handleAlertClick}
            />
          </Stack>
        </HomeMonitor>
      )
}
