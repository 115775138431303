import {useState, useEffect} from 'react';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {HomeAssistant} from '@homefile/components';
import { setDrawer } from "../../../features/drawer/drawerSlice";
import {setPanel, setStep} from "../../../features/assistant/assistantSlice"
import {useHomeAssistant} from "../../../hooks/useHomeAssistant"
import {HomeMonitorModule} from "./HomeMonitorModule"

export const HomeAssistantModule = ({address}: {address: any}) => {
    const dispatch = useAppDispatch()
    const currentPanel = useAppSelector((state: RootState) => state.assistant.currentPanel)
    const {completeNum} = useHomeAssistant()
    const [completedStep, updateCompletedStep] = useState(1)
    const [showTutorial, updateShowTutorial] = useState(true)

    const handleStepClick = (step: number) => {
        dispatch(setPanel({currentPanel: step}))
        dispatch(setStep({currentStep: step}))
        dispatch(setDrawer({ location: "right", content:'HomeAssistant', size: "md"}))
    }

    useEffect(() => {
        if (completeNum === 0) {
            updateShowTutorial(true)
            updateCompletedStep(1)
        } else if (completeNum < 6) {
            updateCompletedStep(completeNum + 1)
            updateShowTutorial(false)
        } else {
            updateCompletedStep(7)
            updateShowTutorial(false)
        }
    }, [completeNum])

    return (
        <>
        {
            completeNum < 6 ? (
                <HomeAssistant 
                    showTutorial={showTutorial}
                    currentStep={completedStep}
                    onStepClick={handleStepClick}
                    currentPanel={currentPanel}
                    tutorialUrl={""}
                />
            ) : (
                <HomeMonitorModule address={address} />
            )
        }
        </>
    )
}

