import { homefileApi } from './emptySplitApi'

const reportApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        createReportForm: builder.mutation({
            query: (data: any) => ({
                url: "/api/report/form",
                method: "POST",
                body: data,
                headers: {
                "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["Forms"],
        }),
        getForms: builder.query({
            query: (data: any) => ({
                url: "/api/report/forms",
                params: {type: `${data.type}`, subType: `${data.subType}`},
                headers: {
                Pagination: '{"page": 1, "limit": 10}',
                },
            }),
            providesTags: ["Forms"],
        }),
        getProgressReports: builder.query({
            query: (data: any) => ({
                url: `/api/report/progress-reports/${data.homeId}`,
                params: {type: `${data.type}`, subType: `${data.subType}`},
                headers: {
                Pagination: '{"page": 1, "limit": 5000}',
                },
            }),
            providesTags: ["ProgressReports"],
        }),
        getProgressReport: builder.query({
            query: (data: any) => ({
                url: `/api/report/${data.documentId}`,
            }),
            providesTags: ["ProgressReport"],
        }),
        createProgressReport: builder.mutation({
            query: (data: any) => ({
                url: "/api/report/progress-report",
                method: "POST",
                body: data,
                headers: {
                "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["ProgressReport", "ProgressReports", "Rooms", "Room"],
        }),
        getUserContacts: builder.query({
            query: () => ({
                url: `/api/report/progress-reports/contacts`,
            }),
            providesTags: ["ProgressReport"]
        }),
        shareContact: builder.mutation({
            query: (data: any) => ({
                url: "/api/report/share-contact",
                method: "POST",
                body: data,
                headers: {
                    "Content-Type": "application/json",
                },
            }),
            // invalidatesTags: ["ProgressReport", "ProgressReports"],
        }),
        updateProgressReport: builder.mutation({
            query: (data: any) => ({
                url: `/api/report/${data.id}`,
                method: "PATCH",
                body: data,
                headers: {
                "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["ProgressReport", "ProgressReports", "Rooms", "Room", "Receipts"],
        }),
        sendProgressReport: builder.mutation({
            query: (data: any) => ({
                url: "/api/report/complete",
                method: "PATCH",
                body: data,
                headers: {
                "Content-Type": "application/json",
                },
            }),
        }),
        deleteReport: builder.mutation({
            query: (id: string) => ({
                url: `/api/report/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ProgressReport", "ProgressReports", "Folders", "Rooms", "Room"],
        }),
        deleteItem: builder.mutation({
            query: (id: string) => ({
                url: `/api/report/exclude/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ProgressReport", "ProgressReports", "Folders", "Rooms", "Room"],
        }),
        restoreItem: builder.mutation({
            query: (id: string) => ({
                url: `/api/report/restore/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["ProgressReport", "ProgressReports", "Folders", "Rooms", "Room"],
        }),
        deleteReportImage: builder.mutation({
            query: (data: any) => ({
                url: `/api/report/image/${data.id}`,
                method: "DELETE",
                body: data.body
            }),
            invalidatesTags: ["ProgressReport", "ProgressReports"],
        }),
        getHomeReportsSetToDelete: builder.query({
            query: () => ({
              url: "api/report/set-for-deletion",
            }),
            providesTags: ["ProgressReports", "Home"],
        }),
    }),
    overrideExisting: false,
})
  
export const { 
    useCreateReportFormMutation,
    useGetFormsQuery,
    useCreateProgressReportMutation,
    useUpdateProgressReportMutation,
    useGetProgressReportQuery,
    useGetProgressReportsQuery,
    useSendProgressReportMutation,
    useDeleteReportMutation,
    useDeleteReportImageMutation,
    useDeleteItemMutation,
    useRestoreItemMutation,
    useGetHomeReportsSetToDeleteQuery,
    useGetUserContactsQuery,
    useShareContactMutation
} = reportApi