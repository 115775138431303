import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { RootState } from "../../app/store"
import { clearAuth } from "../../features/auth/authSlice"

// https://homefile-backend-tbkyzthbwq-uc.a.run.app
// http://localhost:4000
const baseUrl = process.env.REACT_APP_BASE_HOMEFILE_API_URL
  ? process.env.REACT_APP_BASE_HOMEFILE_API_URL
  : "https://homefile-backend-tbkyzthbwq-uc.a.run.app"

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }

    const homeId = (getState() as RootState).home.id
    if (homeId) {
      headers.set("home", homeId)
    }

    return headers
  },
})

const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // force navigate to login if token not authorized
    api.dispatch(clearAuth())
  }
  return result
}

export const homefileApi = createApi({
  reducerPath: "homefileApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: [
    "Homes",
    "User",
    "Users",
    "Home",
    "PropertyData",
    "Forms",
    "Form",
    "ProgressReports",
    "ProgressReport",
    "Folders",
    "Rooms",
    "Room",
    "Items",
    "Payment",
    "Subscription",
    "Receipts",
    "Partners",
    "Notifications",
    "Contacts"
  ],
  endpoints: () => ({})
})

